import React, { useEffect, useState } from "react";
import Map from "@arcgis/core/Map";
import SceneView from "@arcgis/core/views/SceneView";
import GraphicsLayer from "@arcgis/core/layers/GraphicsLayer";
import Graphic from "@arcgis/core/Graphic";
import Point from "@arcgis/core/geometry/Point";
import Polygon from "@arcgis/core/geometry/Polygon";
import PolygonSymbol3D from "@arcgis/core/symbols/PolygonSymbol3D";
import ExtrudeSymbol3DLayer from "@arcgis/core/symbols/ExtrudeSymbol3DLayer";
import SimpleFillSymbol from "@arcgis/core/symbols/SimpleFillSymbol";
import SimpleLineSymbol from "@arcgis/core/symbols/SimpleLineSymbol";
import ObjectSymbol3DLayer from "@arcgis/core/symbols/ObjectSymbol3DLayer";
import "@arcgis/core/assets/esri/themes/light/main.css";
import axios from "./axios";

const EsriMap = () => {
  const [buildings, setBuildings] = useState([]);
  const [accessData, setAccessData] = useState(null);
  const [mapObjects, setMapObjects] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [dataCoordinates, setDataCoordinates] = useState();
  const [formValues, setFormValues] = useState();
  const [threeDObjectData, set3dObjectData] = useState([]);
  const [objectData, setObjectData] = useState({});
  const [threeDObjectCategories, set3dObjectCategories] = useState([]);
  const [objectCategories, setObjectCategories] = useState([]);
  const [selectedCategoryId, setSelectedCategoryId] = useState(null);
  const [selectedCategories, setSelectedCategories] = useState([]); // Track selected categories
  const [filteredBuildings, setFilteredBuildings] = useState([]);

  const getData = async () => {
    try {
      const res = await axios.get("/eharta-access/all");
      const responseData = res?.data?.data || res;
      setAccessData(responseData?.access);
    } catch (err) {
      console.log(err);
    }
  };

  const get3dObjectCategories = async () => {
    try {
      const res = await axios.get("/threeD-categories/all");
      const responseData = res?.data?.data || res;
      set3dObjectCategories(responseData);
    } catch (err) {
      console.log(err);
    }
  };
  const getObjectCategories = async () => {
    try {
      const res = await axios.get("/building-category/all");
      const responseData = res?.data?.data || res;
      setObjectCategories(responseData);
    } catch (err) {
      console.log(err);
    }
  };

  const get3DObjectData = async () => {
    try {
      const res = await axios.get("/threeD-objects/all");
      const responseData = res?.data?.data || res;
      set3dObjectData(responseData);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getData();
    get3DObjectData();
    get3dObjectCategories();
    getObjectCategories();
  }, []);

  useEffect(() => {
    if (accessData) {
      axios
        .get("/building/all")
        .then((res) => {
          const responseData = res?.data?.data || res;
          setBuildings(responseData);
          setFilteredBuildings(responseData);
        })
        .catch((err) => {
          console.log(err);
        });

      axios
        .get("/map-objects/all")
        .then((res) => {
          const responseData = res?.data?.data || res;
          setMapObjects(responseData);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [accessData]);

  const add3DObjectsFromArray = (objects) => {
    const graphicsLayer = new GraphicsLayer();
    objects.forEach((obj) => {
      const objectPoint = new Point({
        longitude: obj.longitude,
        latitude: obj.latitude,
        z: obj.altitude, // Use the altitude from the object
        spatialReference: { wkid: 4326 },
      });

      const objectSymbol = {
        type: "point-3d", // Autocasts as new PointSymbol3D()
        symbolLayers: [
          new ObjectSymbol3DLayer({
            resource: {
              href: process.env.REACT_APP_UPLOADS + "/" + obj.object_href, // Use the object_href from API response
            },
            height: obj.height, // Use the object's height
            tilt: obj.tilt,
            heading: obj.heading, // Use the object's heading
          }),
        ],
      };

      const objectGraphic = new Graphic({
        geometry: objectPoint,
        symbol: objectSymbol,
      });

      graphicsLayer.add(objectGraphic);
    });

    return graphicsLayer;
  };

  useEffect(() => {
    if (accessData) {
      const map = new Map({
        basemap: "topo-vector",
        ground: "world-elevation",
      });

      const view = new SceneView({
        container: "viewDiv",
        qualityProfile: "high",
        map: map,
        camera: {
          position: [21.146644, 42.644623, 1590],
          tilt: 60,
        },
      });

      const graphicsLayer = new GraphicsLayer();
      map.add(graphicsLayer);

      view.on("click", (event) => {
        const { mapPoint } = event;
        const lat = mapPoint?.latitude;
        const lon = mapPoint?.longitude;
        const altitude = mapPoint?.z;
        console.log(`Latitude: ${lat}, Longitude: ${lon} ${altitude}`);
        setModalOpen(true);
        setDataCoordinates(mapPoint);
      });

      const layer = add3DObjectsFromArray(mapObjects);
      map.add(layer);

      filteredBuildings?.forEach((building) => {
        addBuildingWithSpaces(building);
      });

      function addBuildingWithSpaces(building) {
        const {
          buildingName,
          convertedObjects,
          convertedParcels,
          floorsHeight,
          absoluteQuotas,
          heightFloors,
          colors,
        } = building;

        const sliceHeight = 0.2; // Total slice height

        // Add 2D outline with dynamic width
        const outlinePolygon = new Polygon({
          rings: convertedParcels,
          spatialReference: { wkid: 4326 },
        });

        const outlineSymbol = new SimpleFillSymbol({
          color: [0, 0, 0, 0],
          outline: new SimpleLineSymbol({
            color: [255, 0, 0],
            width: 2,
          }),
        });

        const outlineGraphic = new Graphic({
          geometry: outlinePolygon,
          symbol: outlineSymbol,
        });

        graphicsLayer.add(outlineGraphic);
        let floorTest;
        if (floorsHeight === 1) {
          floorTest = new Polygon({
            rings: convertedObjects.map((ring) => [ring[0], ring[1], ring[2]]),
            spatialReference: { wkid: 4326 },
          });
        }
        const color = colors[0 % colors.length];
        const floorSymbol = new PolygonSymbol3D({
          symbolLayers: [
            new ExtrudeSymbol3DLayer({
              material: { color: color },
              size:
                heightFloors[0] - (0 < floorsHeight - 1 ? sliceHeight / 2 : 0),
            }),
          ],
        });
        const floorGraphic2 = new Graphic({
          geometry: floorTest,
          symbol: floorSymbol,
          attributes: {
            buildingName: buildingName,
            // floor: i + 1,
          },
        });

        graphicsLayer.add(floorGraphic2);

        for (let i = 0; i < floorsHeight; i++) {
          if (floorsHeight === 1) {
            break;
          }
          const altitude = absoluteQuotas[i];
          const floorHeight =
            heightFloors[i] - (i < floorsHeight - 1 ? sliceHeight / 2 : 0);
          const color = colors[i % colors.length];

          const floorPolygon = new Polygon({
            rings: convertedObjects.map((ring) => [ring[0], ring[1], altitude]),
            spatialReference: { wkid: 4326 },
          });

          const floorSymbol = new PolygonSymbol3D({
            symbolLayers: [
              new ExtrudeSymbol3DLayer({
                material: { color: color },
                size: floorHeight,
              }),
            ],
          });

          const floorGraphic = new Graphic({
            geometry: floorPolygon,
            symbol: floorSymbol,
            attributes: {
              buildingName: buildingName,
              floor: i + 1,
            },
          });

          graphicsLayer.add(floorGraphic);

          // Add slice (gap) between floors if it's not the last floor
          if (i < floorsHeight - 1) {
            const sliceAltitude = altitude + floorHeight;

            const slicePolygon = new Polygon({
              rings: convertedObjects.map((ring) => [
                ring[0],
                ring[1],
                sliceAltitude,
              ]),
              spatialReference: { wkid: 4326 },
            });

            const sliceSymbol = new PolygonSymbol3D({
              symbolLayers: [
                new ExtrudeSymbol3DLayer({
                  material: { color: "#FFFFFF" },
                  size: sliceHeight,
                }),
              ],
            });

            const sliceGraphic = new Graphic({
              geometry: slicePolygon,
              symbol: sliceSymbol,
              attributes: {
                buildingName: buildingName,
                slice: i + 1,
              },
            });

            graphicsLayer.add(sliceGraphic);
          }
        }
      }
    }
  }, [buildings, accessData, filteredBuildings]);

  if (accessData === null) {
    return <div>Loading...</div>;
  }

  if (!accessData) {
    return (
      <div className="container d-flex justify-content-center align-items-center vh-100">
        <div className="card shadow-lg">
          <div className="card-body text-center">
            <h1 className="display-4 text-danger">
              <i className="bi bi-exclamation-triangle-fill"></i>
            </h1>
            <h2 className="card-title">Access Denied</h2>
            <p className="card-text">
              You don't have the necessary permissions to view this content.
            </p>
          </div>
        </div>
      </div>
    );
  }

  const handleCheckboxChange = (categoryId) => {
    if (categoryId === "all") {
      setSelectedCategories(["all"]);
    } else {
      setSelectedCategories((prevSelected) =>
        prevSelected.includes(categoryId)
          ? prevSelected.filter((id) => id !== categoryId)
          : [...prevSelected.filter((id) => id !== "all"), categoryId]
      );
    }
  };

  // Apply filter
  const filterBuildings = () => {
    if (selectedCategories.includes("all") || selectedCategories.length === 0) {
      setFilteredBuildings(buildings); // Show all if "All" is selected or no filters selected
    } else {
      setFilteredBuildings(
        buildings.filter((building) =>
          selectedCategories.includes(building.buildingCategory)
        )
      );
    }
  };

  const handleChange = (e) => {
    setFormValues({
      ...formValues,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    let saveObject = {
      name: objectData.name || "Test", //value e object qe e marr kur e preku select
      latitude: dataCoordinates?.latitude,
      longitude: dataCoordinates?.longitude,
      altitude: dataCoordinates?.z,
      object_href: objectData?.threeD_object_url || "/uploads/objects/tree.glb", //value e object qe e marr kur e preku select
      height: objectData?.height || 4, //value e object qe e marr kur e preku select
      tilt: objectData?.tilt || Number(formValues?.tilt) || 0,
      heading: objectData?.heading || Number(formValues?.heading) || 0,
    };
    if (objectData && Object.keys(objectData).length > 0) {
      axios
        .post("/map-objects", saveObject)
        .then((res) => {
          setModalOpen(false);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      alert("Please Select an object");
    }

    // setMapObjects((prevObjects) => {
    //   const updatedObjects = [...prevObjects, saveObject];

    //   // Call the function to update the map with the new objects
    //   add3DObjectsFromArray([saveObject]); // Only add the newly created object to the map

    //   return updatedObjects;
    // });
  };

  const filteredObjects = selectedCategoryId
    ? threeDObjectData.filter(
        (el) => el.threeD_categorieId === selectedCategoryId
      )
    : threeDObjectData;
  return (
    <>
      <div id="viewDiv" style={{ height: "100vh", width: "100%" }}></div>
      <div
        style={{ position: "absolute", top: "40%", left: "0.5%", zIndex: 100 }}
      >
        <button
          class="btn btn-secondary"
          type="button"
          data-bs-toggle="offcanvas"
          data-bs-target="#offcanvasExample"
          aria-controls="offcanvasExample"
        >
          <i class="fa fa-object-group"></i>
        </button>

        <div
          class="offcanvas offcanvas-start"
          tabindex="-1"
          id="offcanvasExample"
          aria-labelledby="offcanvasExampleLabel"
        >
          <div class="offcanvas-header">
            <h5 class="offcanvas-title" id="offcanvasExampleLabel">
              3D Objects
            </h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
            ></button>
          </div>
          <div class="offcanvas-body">
            <div>Choose the object u want to add to the map</div>
            <div className="mt-5">
              <div className="mb-3">
                <label htmlFor="categorySelect" className="form-label">
                  Select Category
                </label>
                <select
                  id="categorySelect"
                  className="form-select"
                  onChange={(e) => setSelectedCategoryId(e.target.value)}
                >
                  <option value="">All Categories</option>
                  {threeDObjectCategories.map((category) => (
                    <option key={category.id} value={category.id}>
                      {category.name}
                    </option>
                  ))}
                </select>
              </div>

              {filteredObjects.length > 0 ? (
                filteredObjects.map((el, index) => (
                  <div
                    className="col-md-12 d-flex align-items-center justify-content-between mb-3"
                    key={index}
                  >
                    <div className="col-md-2">
                      <img
                        src={`${process.env.REACT_APP_UPLOADS}/${el.threeD_object_image}`}
                        className="img-fluid"
                        alt={el.name}
                      />
                    </div>
                    <div className="col-md-4">
                      <p>{el.name}</p>
                    </div>
                    <div className="col-md-3">
                      <button
                        className="btn btn-outline-success"
                        data-bs-dismiss="offcanvas"
                        onClick={() => setObjectData(el)}
                      >
                        Zgjedh
                      </button>
                    </div>
                    <div className="col-md-1">
                      {objectData?.id === el.id ? (
                        <i className="fa fa-check text-success"></i>
                      ) : (
                        ""
                      )}
                    </div>
                    <hr />
                  </div>
                ))
              ) : (
                <p>No objects found for the selected category.</p>
              )}
            </div>
          </div>
        </div>
      </div>
      <div
        style={{ position: "absolute", top: "30%", left: "0.5%", zIndex: 95 }}
      >
        <button
          class="btn btn-secondary"
          onClick={() => setSelectedCategories([])}
          type="button"
          data-bs-toggle="offcanvas"
          data-bs-target="#offcanvasRight"
          aria-controls="offcanvasRight"
        >
          <i class="fa fa-filter"></i>
        </button>

        <div
          class="offcanvas offcanvas-end"
          tabindex="-1"
          id="offcanvasRight"
          aria-labelledby="offcanvasRightLabel"
        >
          <div class="offcanvas-header">
            <h5 class="offcanvas-title" id="offcanvasExampleLabel">
              3D Objects
            </h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
            ></button>
          </div>
          <div class="offcanvas-body">
            <div>Choose the object u want to see on the map</div>
            <div className="mt-5">
              <div className="mb-3">
              <div className="row justify-content-between">
              <div className="col-md-8">
                <label>All Buildings</label>
              </div>
              <div className="col-md-4">
                <input
                  className="form-check-input"
                  type="checkbox"
                  checked={selectedCategories.includes("all")}
                  onChange={() => handleCheckboxChange("all")}
                />
              </div>
            </div>
                {objectCategories.map((category) => (
                  <>
                    <div
                      key={category.id}
                      className="row justify-content-between"
                    >
                      <div className="col-md-8">
                        <option key={category.id} value={category.id}>
                          {category.name}
                        </option>
                      </div>
                      <div className="col-md-4">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          checked={selectedCategories.includes(category.name)}
                          onClick={() => handleCheckboxChange(category.name)}
                        />
                      </div>
                    </div>
                  </>
                ))}
                <button
                  className="btn btn-success w-100 mt-5"
                  data-bs-dismiss="offcanvas"
                  onClick={filterBuildings}
                >
                  Filter
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {modalOpen && (
        <div
          className="modal-test"
          style={{
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            backgroundColor: "white",
            padding: "20px",
            zIndex: 1000,
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
          }}
        >
          <h2>Coordinates</h2>
          <p>Latitude: {dataCoordinates?.latitude}</p>
          <p>Longitude: {dataCoordinates?.longitude}</p>
          <p>Altitude: {dataCoordinates?.z}</p>
          <form>
            <div>
              <label htmlFor="tilt">Tilt:</label>
              <input
                type="number"
                id="tilt"
                name="tilt"
                className="form-control"
                onChange={handleChange}
                required
              />
            </div>

            <div>
              <label htmlFor="heading">Heading:</label>
              <input
                type="number"
                id="heading"
                name="heading"
                className="form-control"
                onChange={handleChange}
                required
              />
            </div>
            <div className="d-flex">
              <button
                className="btn btn-danger mt-4 w-50"
                type="submit"
                onClick={() => {
                  setModalOpen(false);
                  setFormValues(null);
                }}
              >
                Close
              </button>
              <button
                className="btn btn-success mt-4 w-50 ms-2"
                type="submit"
                onClick={handleSubmit}
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      )}
      {modalOpen && (
        <div
          className="modal-overlay-test"
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            zIndex: 999,
          }}
          onClick={() => {
            setModalOpen(false);
            setFormValues(null);
          }} // Close the modal when the overlay is clicked
        />
      )}
    </>
  );
};

export default EsriMap;
